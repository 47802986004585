
import Button from '@/components/Button.vue'
import useRequest from '@/hooks/useRequest'
import { getAddressSchema } from '@/pages/setting/setting'
import { readAddress, saveAddress } from '@/pages/setting/setting.api'
import Holder from '@/provider/Holder.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { Data } from '@/types'
import Form from 'common/form/Form.vue'
import FInput from 'common/form/inputs/FormInput.vue'
import Textarea from 'common/form/inputs/Textarea.vue'
import { defineComponent, ref, shallowRef } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'AddressEdit',
  components: { Button, Form, FInput, PageWithHeader, Textarea, Holder },
  setup () {
    const form = ref<HTMLFormElement | null>(null)
    const schema = shallowRef({})
    const loaded = shallowRef(false)
    const router = useRouter()
    const [request, progress] = useRequest(saveAddress)

    readAddress().then(resp => {
      schema.value = getAddressSchema(resp)
      loaded.value = true
    })

    return {
      form,
      schema,
      loaded,
      progress,
      bind (data: Data) {
        return request(data).then(() => {
          router.back()
        })
      },
      submit () {
        (form.value as HTMLFormElement).submit()
      },
    }
  },
})
